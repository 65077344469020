@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5% 64.9%;
    --radius: 0.5rem;
    --scrollbar-track: ;
    --scrollbar-thumb: rgb(222, 222, 222);
    --scrollbar-thumb-hover: rgb(222, 222, 222);
    --scrollbar-thumb-border: ;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --radius: 0.5rem;
    --scrollbar-track: ;
    --scrollbar-thumb: rgb(44, 44, 44);
    --scrollbar-thumb-hover: rgb(44, 44, 44);
    --scrollbar-thumb-border: ;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  html {
    @apply scroll-smooth;
  }
  body {
    @apply bg-background text-foreground;
    /* font-feature-settings: "rlig" 1, "calt" 1; */
    font-synthesis-weight: none;
    text-rendering: optimizeLegibility;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute w-9 h-9 bg-muted rounded-full font-mono font-medium text-center text-base inline-flex items-center justify-center -indent-px border-4 border-background;
    @apply ml-[-50px] mt-[-4px];
    content: counter(step);
  }

  .chunk-container {
    @apply shadow-none;
  }

  .chunk-container::after {
    content: "";
    @apply absolute -inset-4 shadow-xl rounded-xl border;
  }
}

@media (max-width: 640px) {
  .container {
    @apply px-4;
  }
}

.fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 1000 !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Custom Scrollbar Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
/*   background: var(--scrollbar-track); */
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 14px;
/*   border: 3px solid var(--scrollbar-thumb-border); */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}


footer {
  padding: 2px;
  text-align: center;
}

footer nav div {
  margin: 0;
}

#preference-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #000;
  color: #fff;
  padding: 10px;
  text-align: center;
  display: none;
}

#preference-banner button {
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
}

#content {
  padding: 40px;
}

.versionText {
  position: absolute;
  right: 0px;
  bottom: 20%; 
  transform: rotate(-90deg);
  transform-origin: bottom right;
  font-size: 100px;
  font-weight: bold;
  color: #000;
}

/* Loader Spinner */
.loader {
  border-top-color: #3498db;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
}

.pulse {
  animation: pulse 2s infinite; /* Adjust the duration here */
}